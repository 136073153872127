<template>
  <div>
    <title>SIM INVENTORY ~ UPDATE PRODUCTION PROCESS DATA FROM PRODUCTION PROCESS</title>
    <section class="content-header">
      <h1>
        Update Production Process Data from Production Process
        <br />
        <h4>Please edit the production process transaction data from the production process</h4>
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Production Process Transactions from Production Process</li>
      </ol>
    </section>
    <section class="content">
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      
      <div class="row">
        <!-- left column -->
        <div class="col-md-12">
          <!-- general form elements -->
          <div class="box box-primary">
            <div class="box-header with-border">
              <h3 class="box-title">Production Process Data from Production Process</h3>
            </div>
            <!-- /.box-header -->
            <!-- form start -->
            <form role="form">
              <div class="box-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Production Number</label>
                  <input
                    type="text"
                    v-model="prodnumb"
                    readonly
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="idxprosesproduksi"
                    autocomplete="off"
                    class="form-control"
                  />
                  <input
                    type="hidden"
                    v-model="idxprosesproduksidariprosesproduksi"
                    autocomplete="off"
                    class="form-control"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Notes</label>
                  <input
                    type="text"
                    v-model="notesprosesproduksidariprosesproduksi"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Qty</label>
                  <input
                    type="number"
                    v-model="qtyprosesproduksidariprosesproduksi"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Notes"
                  />
                </div>
              </div>

              <div class="box-footer">
                <button
                  @click="updateData()"
                  type="button"
                  class="btn btn-primary"
                > <i class="fa fa-save"></i>
                  Update
                </button>
                &nbsp;
                <router-link to="/proses-produksi">
                  <button class="btn btn-success"><i class="fa fa-angle-double-left"></i> Back</button>
                </router-link>
              </div>
            </form>
          </div>
        </div>
        
      </div>
    </section>
  </div>
</template>
<script>
import swal from "sweetalert";
var axios = require("axios");
export default {
  name: "edittdata",
  data() {
    return {
      isInputActive: false,
      loading: false,
      prodnumb: "",
      idxprosesproduksi: "",
      idxprosesproduksidariprosesproduksi: "",
      notesprosesproduksidariprosesproduksi: "",
      qtyprosesproduksidariprosesproduksi: "",
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl + "proses_produksi_d_dari_proses_produksi/getproses_produksi_d_dari_proses_produksibyid?id=" + this.$route.params.id;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers })
        .then((resp) => {
          this.idxprosesproduksi = resp.data.data.id_proses_produksi;
          this.idxprosesproduksidariprosesproduksi = resp.data.data.id;
          this.prodnumb = resp.data.data.nomor_produksi;
          this.notesprosesproduksidariprosesproduksi = resp.data.data.notes;
          this.qtyprosesproduksidariprosesproduksi = resp.data.data.qty;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async updateData() {
      this.loading = true;
      // alert(this.$route.params.id);
      var numbprod = this.prodnumb;
      var catatan = this.notesprosesproduksidariprosesproduksi;
      var qty = this.qtyprosesproduksidariprosesproduksi;
      var idx = this.idxprosesproduksidariprosesproduksi;
      if (numbprod == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Production Number can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (catatan == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Notes can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else if (qty == "") {
        swal({
          icon: "warning",
          title: "Warning",
          text: "Qty can't be empty",
          showConfirmButton: false
        });
        this.loading = false;
      } else {
        const kodeuser = sessionStorage.getItem("kodeuser");
        const paramdata = {
          nomor_produksi: this.prodnumb,
          id_proses_produksi: this.idxprosesproduksi,
          notes: this.notesprosesproduksidariprosesproduksi,
          qty: this.qtyprosesproduksidariprosesproduksi,
          kode_user: kodeuser,
        };
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIUpdate = this.$apiurl + "proses_produksi_d_dari_proses_produksi/updateproses_produksi_d_dari_proses_produksi/" + idx;

        const headers = {
          Accept: "application/json",
          Authorization: tokenlogin
        };
        axios
          .put(urlAPIUpdate, paramdata, { headers: headers })
          .then((resp) => {
            // console.log(resp);
            if (resp.data.status == true) {
              swal({
                icon: "success",
                title: "Success",
                text: "Update data successfully",
                showConfirmButton: false
              });
              this.loading = false;
              this.$router.push({ name: "prosesproduksiheader" });
            } else {
              swal({
                icon: "warning",
                title: "Warning",
                text: "Update data failed",
                showConfirmButton: false
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data,
              showConfirmButton: false
            });
            console.log(err);
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
